import SvgIcon from 'components/atoms/SvgIcon';
import Carousel from 'components/common/Carousel';
import Lock from 'components/Icons/Lock';
import { Image } from 'components/views';
import { motion } from 'framer-motion/dist/framer-motion';
import { useFindOutDragging } from 'hooks/custom/useFindOutDragging';
import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import MyReviewProfile from './myReviewProfile';

const ImageGallery = ({ attachments, onClick }) => {
  if (!attachments || attachments.length === 0) return null;
  return attachments.length > 1 ? (
    <Carousel
      style={{
        height: '20vh',
        marginTop: '12px',
      }}
    >
      <motion.div style={{ display: 'flex', gap: '5px', width: '100vw' }}>
        {attachments.slice(0, 5).map((src, index) => (
          <ImageItem key={index} src={src} onClick={onClick} />
        ))}
      </motion.div>
    </Carousel>
  ) : (
    <SingleImage src={attachments[0]} onClick={onClick} />
  );
};

const ImageItem = ({ onClick, src }) => (
  <MultipleImageBox onClick={onClick}>
    <Image
      loading="lazy"
      scale="1x1"
      src={src}
      style={{
        display: 'block',
        height: '100%',
        objectFit: 'cover',
        width: '100%',
      }}
      onDragStart={(e) => e.preventDefault()}
    />
  </MultipleImageBox>
);

const SingleImage = ({ onClick, src }) => (
  <SingleImageBox>
    <Image
      loading="lazy"
      scale="16x9"
      src={src}
      style={{
        display: 'block',
        height: '100%',
        objectFit: 'cover',
        width: '100%',
      }}
      onClick={onClick}
    />
  </SingleImageBox>
);

const MyReviewItem = ({
  expand = false,
  item,
  profile,
  remove,
  reply = false,
  setExpand = () => {},
  type,
  warning,
}) => {
  const isContent = type === 'content';
  const history = useHistory();
  const [isMore, setIsMore] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { isDragging } = useFindOutDragging();
  const { comment } = item;

  const handleMore = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const transWord = (str) => {
    if (!str) return '';
    let temp = str.search(/&#[0-9]{3};/);
    while (temp !== -1) {
      // eslint-disable-next-line no-param-reassign
      str = str.replace(
        /&#[0-9]{3};/,
        String.fromCharCode(Number(str.substr(temp + 2, 3)))
      );
      temp = str.search(/&#[0-9]{3};/);
    }
    return str;
  };

  const click = useCallback(() => {
    if (isDragging) return;
    if (warning) return warning();
    const uriArr = item.uri.split('/');
    const removedTargetIdUri = uriArr.slice(0, uriArr.length - 1).join('/');
    const targetId = uriArr.pop();
    history.push({
      pathname: `/${removedTargetIdUri}`,
      search: `?target=${targetId}`,
    });
  }, [history, isDragging, item.uri, warning]);

  useEffect(() => {
    setIsOpen(false);
  }, [comment]);

  return (
    <MyReviewItemStyle
      className={`comment-area ${item.isActivatedStore ? '' : 'DISABLE'} ${
        reply ? 'REPLY' : ''
      }`}
    >
      <li className="inner-section">
        <div className="item fixed">
          <div className="category" />
          <div className="my-review-info">
            <div className="info-content">
              <MyReviewProfile
                expand={expand}
                item={item}
                modify={reply ? false : () => click()}
                profile={profile}
                remove={remove}
                reply={reply}
                setExpand={setExpand}
              />
              <CarouselSection>
                <ImageGallery attachments={item.attachments} onClick={click} />
              </CarouselSection>
              <div className="info-text" onClick={() => click()}>
                <div
                  className={`review-opponent ${
                    isContent ? 'alter-style' : ''
                  }`}
                  onClick={() => {
                    if (isContent) click();
                  }}
                >
                  <strong>{transWord(item.title)}</strong>
                  {isContent && <i className="fas fa-chevron-right" />}
                </div>
                <pre
                  ref={(ref) => {
                    if (ref) {
                      if (ref.offsetHeight < 150) setIsMore(false);
                      else setIsMore(true);
                    }
                  }}
                  className={isOpen ? '' : 'active'}
                >
                  {item.secret === 'on' && (
                    <figure className="secret-mode-img">
                      <Lock />
                    </figure>
                  )}
                  {item.comment}
                </pre>
                {isMore && (
                  <button
                    className={`more-btn ${isOpen ? 'active' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMore();
                    }}
                  >
                    <span>{isOpen ? '접기' : '더보기'}</span>
                    <SvgIcon
                      height={18}
                      name="arrow_bottom"
                      style={{ marginLeft: '-5px' }}
                      width={18}
                      pointer
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </li>
      {item.replies?.map((replyItem, i) => {
        return (
          <MyReviewItem
            key={i}
            item={replyItem}
            profile={{
              id: replyItem.memberCode,
              name: replyItem.name,
              profile: replyItem.profileImage,
            }}
            reply
          />
        );
      })}
    </MyReviewItemStyle>
  );
};

const CarouselSection = styled.div`
  overflow: hidden;
`;

const MultipleImageBox = styled.div`
  max-width: 158px;
  min-width: 132px;
  width: 100%;
  flex-shrink: 0;

  @media (max-width: 158px) {
    transform: translateY('100%');
    transition: transform 0.3s ease-in-out;
    max-width: 158px;
    min-width: 132px;
    width: 100%;
  }
`;

const SingleImageBox = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 250px;
  min-height: 250px;
`;

const MyReviewItemStyle = styled.div`
  & + .comment-area {
    margin-top: 0 !important;
  }
  * {
    padding: 0;
    margin: 0;
  }
  & .item.fixed {
    padding: 16px;
    border-width: 0 0 1px;
    & > .category {
      overflow: hidden;
      & > strong {
        float: left;
        display: block;
        width: 110px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        background: #0e4e97;
        border-radius: 5px;
        color: #fff;
      }
      & > .spans {
        float: right;
        & > span {
          height: 24px;
          line-height: 24px;
          font-size: 13px;
          color: #777;
          & + span {
            margin-left: 4px;
          }
        }
      }
    }
    & > .my-review-info {
      & > .store-title {
        display: flex;
        & > figure {
          width: fit-content;
          height: 0;
          &.EXIST {
            height: fit-content;
          }
          & > img {
            max-width: 60px;
            max-height: 60px;
            object-fit: cover;
          }
        }
        & > div {
          & > h3 {
            font-size: 13px;
            font-weight: 500;
            max-width: 200px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      & > .info-content {
        & > .info-text {
          margin-top: 12px;
          cursor: pointer;
          & > .review-opponent {
            display: flex;
            align-content: center;
            & > * {
              & + * {
                margin-left: 8px;
              }
            }
            & > strong {
              font-size: 14px;
              color: #72787f;
            }
            & > span {
              padding: 0 8px;
              height: 22px;

              line-height: 22px;
              font-size: 12px;
              text-align: center;
              color: #00af89;

              border-radius: 20px;
              background: #d5fdf4;
            }
            &.alter-style {
              display: flex;
              align-items: center;
              width: fit-content;
              cursor: pointer;
              & > * {
                color: #1c79bc;
              }
              & > strong {
              }
              & > i {
                height: 22px;
                line-height: 22px;
              }
            }
          }
          & > pre {
            margin-top: 12px;
            font-size: 14px;
            color: #26282b;
            word-break: break-all;
            line-height: 25px;
            & > .secret-mode-img {
              display: inline-block;
              margin-right: 4px;
              vertical-align: middle;
            }
          }
          & > pre.active {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;
          }
          & > .more-btn {
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: transparent;
            height: 25px;
            cursor: pointer;
          }
          & > .more-btn.active {
            & > svg {
              transform: rotate(180deg);
            }
          }
        }
        & > .info-imgs {
          margin-top: 16px;
          display: flex;
          & > figure {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;

            border: 1px solid #e3e3e3;
            border-radius: 4px;

            background: #1c79bc;
            overflow: hidden;
            & + figure {
              margin-left: 4px;
            }
            & > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            & > span {
              color: #fff;
            }
          }
        }
        & > .fix-delete {
          padding: 8px 0 16px;
          overflow: hidden;
          & > .right-block {
            float: right;
            & > span {
              font-size: 13px;
              color: #72787f;
              cursor: pointer;
              & + span {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
  &.DISABLE {
    & .info-fix-delete {
      & button.modify-button {
        opacity: 0.4 !important;
      }
    }
  }
  &.REPLY {
    & > .inner-section {
      & > .item.fixed {
        background: #f8f8f8;
      }
    }
  }
`;

export default memo(MyReviewItem);
