import './style.css';

import { useDialogContext } from '@locked-dobby/library-react-dialog';
import * as coupon_api from 'api/coupon';
import { Box } from 'components/atoms';
import Observer from 'components/atoms/Observer';
import { Container, Footer } from 'components/common';
import CustomAlert from 'components/common/customAlert';
import Document from 'components/common/document';
import { HeadNew } from 'components/common/header';
import Search, { TagFilter } from 'components/common/search';
import SortFilter from 'components/common/\bSortFilter';
import CouponList from 'components/coupons/_coupons_new';
import SadPiratesIcon from 'components/Icons/SadPiratesIcon';
import { Loading } from 'components/views';
import { SESSION } from 'context';
import { useGetCouponList } from 'hooks/Query/CouponList';
import useGetRegion from 'hooks/Query/Filter/useGetRegion';
import useObserver from 'hooks/useObserver';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { allCouponsListState } from 'recoil/coupons';
import styled from 'styled-components';

const getSortState = (data) => {
  switch (data) {
    case 'default':
      return '기본순';
    case 'discountRate':
      return '할인율 높은순';
    default:
      return '발급 기간순';
  }
};

const SORT_LIST = [
  { key: 'default', label: '기본순' },
  { key: 'discountRate', label: '할인율 높은순' },
  { key: 'issueDate', label: '발급 기간순' },
];

const CouponsView = ({ session }) => {
  const [popMsg, setPopMsg] = useState();
  const history = useHistory();
  const scrollTarget = useRef(null);

  const [couponList, setCouponList] = useRecoilState(allCouponsListState);

  const { data: filtersData = [] } = useGetRegion();
  const {
    data: couponData = { pages: [{ coupons: [], total: 0 }] },
    fetchNextPage,
    hasNextPage,
    isLoading,
    refetch,
  } = useGetCouponList();

  const { toast } = useDialogContext();

  const newCouponList = useMemo(() => {
    const list = (couponData?.pages || []).reduce((acc, curr) => {
      return [...acc, ...curr.coupons];
    }, []);

    return list;
  }, [couponData]);

  // 쿠폰에 [메뉴쿠폰, 품목쿠폰]두가지 종류가 있습니다.
  // 메뉴쿠폰은 item에 product 노드 안에 쿠폰정보가 들어있고 품목쿠폰은 item에 item 노드 안에 쿠폰정보가 들어있습니다.
  // 하위 컴포넌트에서 product 노드만 바라보고 바인딩 할 수 있도록 여기서 keyName을 변경해줬습니다.
  const keyNameChangedList = useMemo(
    () =>
      newCouponList.map((coupon) => ({
        ...coupon,
        product: coupon.product || coupon.item,
      })),
    [newCouponList]
  );

  const onIntersect = useCallback(
    (entry) => {
      if (hasNextPage && entry.isIntersecting) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useObserver({
    onIntersect,
    target: scrollTarget,
  });

  const clickListSort = (e) => {
    setCouponList((a) => ({ ...a, sort: e.target.id }));
  };

  // TODO: preLoginState의 전반적인 개선이 필요
  const saveCoupon = useCallback(
    async (id) => {
      try {
        const { data } = await coupon_api.save_coupon(id);
        if (data.message === 'data inserted') {
          await refetch();
          setPopMsg('confirm');
        } else if (data.message === 'Already got') {
          setPopMsg('exist');
        }
      } catch (err) {
        console.error('save coupon error >>', err);
        toast({
          message: '쿠폰 저장에 실패했습니다. 다시 시도해주세요.',
        });
      }
    },
    [refetch, toast]
  );

  // 비로그인상태에서 쿠폰받기 후 돌아왔을때 쿠폰 바로 받아지도록
  useEffect(() => {
    const isLogin = Boolean(session.identity);
    const preLoginState = sessionStorage.getItem('preLoginState');
    let couponId;

    if (preLoginState) {
      try {
        const parsedPreLoginState = JSON.parse(preLoginState);
        couponId = parsedPreLoginState?.couponId;
      } catch (error) {
        console.error('Error parsing preLoginState:', error);
      }
    }

    if (isLogin && couponId) {
      sessionStorage.removeItem('preLoginState');
      saveCoupon(couponId);
    }
  }, [saveCoupon, session.identity]);

  const _default_filter = [{ code: 'all', label: '모든 지역' }];
  const _tag_filters = (options) => [
    ..._default_filter,
    ...(options || []).map((t) => ({ code: t.code, label: t.label })),
  ];

  const Popups = {
    confirm: {
      message: '마이페이지 > 수산시장 쿠폰함에서\n 확인 할 수 있습니다.',
      options: [
        {
          callback: () => {
            setPopMsg(false);
            sessionStorage.removeItem('preLoginState');
          },
          label: '닫기',
        },
        {
          callback: () => {
            history.push('/마이페이지/내쿠폰');
            sessionStorage.removeItem('preLoginState');
          },
          label: '받은 쿠폰 보기',
        },
      ],
      title: '쿠폰 받기 완료',
    },
    exist: {
      message: '이미 받은 쿠폰이에요.',
      options: [
        {
          callback: () => {
            setPopMsg(false);
            sessionStorage.removeItem('preLoginState');
          },
          label: '확인',
        },
      ],
      title: '',
    },
  };

  const newState = useResetRecoilState(allCouponsListState);

  useEffect(() => {
    return () => {
      newState();
    };
  }, []);

  const handleFilter = (tag) => {
    setCouponList((prev) => ({ ...prev, region: tag }));
  };

  return (
    <>
      <Document.Head title="전체쿠폰" type="single" />
      <Container bg="#fff">
        <HeaderStyle className="head">
          <HeadNew label="수산시장 전체 쿠폰" />
          <Search>
            <TagFilter
              filterHandler={handleFilter}
              tags={_tag_filters(filtersData)}
              value={couponList.region || { code: 'all' }}
            />
          </Search>
        </HeaderStyle>
        {isLoading && <Loading />}
        <MainStyle className="main">
          <div className="coupons-wrap">
            <TitleWrap>
              <TitleBox>
                <Title>발급 가능 쿠폰</Title>
                <CouponTotal>{couponData.pages[0].totalCount}</CouponTotal>
              </TitleBox>
              {newCouponList.length > 0 && (
                <SortFilter
                  option={SORT_LIST}
                  value={getSortState(couponList.sort) || '기본순'}
                  onChangeSelection={clickListSort}
                />
              )}
            </TitleWrap>
            {newCouponList.length > 0 &&
              keyNameChangedList.map((coupon, i) => (
                <CouponList
                  key={i}
                  coupon={coupon}
                  saveCoupon={saveCoupon}
                  session={session}
                />
              ))}
            {newCouponList.length === 0 && (
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                margin="80px 0"
              >
                <SadPiratesIcon />
                <Box fontSize="16px" marginTop="12px">
                  발급 가능한 쿠폰이 없습니다.
                </Box>
              </Box>
            )}
          </div>
        </MainStyle>

        <Observer ref={scrollTarget} />
        <Footer />
        <CustomAlert visible={Popups[popMsg] || false} />
      </Container>
    </>
  );
};

const HeaderStyle = styled.div`
  padding-top: 56px;

  & .tag-group {
    border: none;
    border-bottom: 1px solid #c9cdd2;
    & .eg-flick-viewport {
      overflow: visible !important;
      & .eg-flick-camera {
        & > .tag-item {
          border: none;
          & > div {
            & > span {
              color: #9fa4aa;
              font-weight: 400;
            }
          }
          &.on {
            & > div {
              & > span {
                color: #26282b;
                font-weight: 500;
              }
            }
            &::after {
              display: block;
              position: absolute;
              bottom: -3px;
              left: 0;

              width: 100%;
              height: 4px;

              content: '';
              background: #000;
            }
          }
        }
      }
    }
  }
`;

const MainStyle = styled.div`
  padding: 0 16px;
  & > .coupons-wrap {
    & > h4 {
      font-size: 14px;
      color: #26282b;
      & > span {
        color: #f2994a;
      }
      & + .coupon-list {
        margin-top: 8px;
      }
    }
    & > .coupon-list {
    }
  }
`;

const TitleWrap = styled.div`
  padding: 32px 0px 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #26282b;
`;

const CouponTotal = styled.div`
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: #72787f;
`;

export default connect(({ [SESSION]: session }) => ({
  session,
}))(CouponsView);
