/* eslint-disable no-nested-ternary */
import getAccountUrl from 'api/getAccountUrl';
import LoginContext, { View } from 'components/common/login/_context';
import { Button, Image } from 'components/views';
import { ToastContext } from 'context/toast';
import React, {
  memo,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { ScoreViewNew } from '.';
import ImageForm from './_image';

const Action = {
  ADD_IMAGE: 'pirates-form/ADD.IMAGE',
  CLEAR: 'pirates-form/CLEAR',
  REMOVE_IMAGE: 'pirates-form/REMOVE.IMAGE',
  SCORES: 'pirates-form/SCORE',
  WRITE: 'pirates-form/WRITE.COMMENT',
};

const reducer = (state, action) => {
  switch (action.type) {
    case Action.SCORES:
      return {
        ...state,
        score: action.payload,
      };
    case Action.WRITE:
      return {
        ...state,
        comment: action.payload,
      };
    case Action.ADD_IMAGE:
      return {
        ...state,
        fileNames: [...state.fileNames, action.fileName],
        images: [...state.images, action.payload],
      };
    case Action.REMOVE_IMAGE:
      return {
        ...state,
        deleteImages: [...state.deleteImages, action.payload],
        fileNames: state.fileNames.filter(
          (_, i) =>
            i !== state.images.findIndex((image) => image === action.payload)
        ),
        images: state.images.filter((image) => image !== action.payload),
      };
    case Action.CLEAR:
      return {
        comment: '',
        deleteImages: [],
        fileNames: [],
        images: [],
        score: 0,
      };
    default:
      return state;
  }
};

const TextForm = ({ children, onSubmit, page, props, scrollRef, tabState }) => {
  const {
    id,
    initImages,
    initScore,
    initSecret,
    initText,
    member,
    parentCode,
    placeHolder,
    secretGroup,
    targetCode,
    toastMessage,
    type,
  } = props;

  const location = useLocation();

  // type = 1 => 시장 상세 리뷰 입력폼
  // type = 2 => 콘텐츠 댓글 입력폼
  const memberName = member.name;

  // 각각 props로 받아오는 member 데이터 안의 key값이 다름
  const memberIdentity = member.identity || member.id;
  const memberProfile = member.profileImage || member.profile;

  const isModify = !!children;
  const isReply = !!parentCode;
  const textRef = useRef(null);
  // const loading = useRef(false);
  const { showToast } = useContext(ToastContext);
  const { action } = useContext(LoginContext);
  const [state, dispatch] = useReducer(reducer, {
    comment: initText,
    deleteImages: [],
    fileNames: [],
    images: initImages ? [...initImages] : [],
    score: initScore,
  });
  const [writing, setWriting] = useState(false);
  const [isSecret, setIsSecret] = useState(initSecret === 'on');
  const isContentText = memberIdentity && !isReply && page === 'content';

  const query = {
    targetCode, // "0000000199",
    type,
  };

  const onChangeText = (e) => {
    e.stopPropagation();

    dispatch({ payload: textRef.current.value, type: Action.WRITE });
  };

  useEffect(() => {
    const tmp = state.comment.split('\n');
    textRef.current.value = tmp.join('\n');
  }, [state.comment]);

  useEffect(() => {
    try {
      const preLoginState = JSON.parse(
        sessionStorage.getItem('preLoginState') || ''
      );

      const nowPath = `${location.pathname}${location.search}`;

      if (preLoginState?.path === nowPath && textRef?.current) {
        setTimeout(() => {
          textRef.current.scrollIntoView({ behavior: 'smooth' });
          sessionStorage.removeItem('preLoginState');
        }, 50);

        textRef.current.focus();
        return;
      }
      // if (preLoginState?.path === nowPath && preLoginState?.scroll?.y) {
      //   setTimeout(() => {
      //     window.scrollTo(
      //       preLoginState?.scroll?.x || 0,
      //       +preLoginState?.scroll?.y,
      //     );
      //     sessionStorage.removeItem('preLoginState');
      //   }, 50);
      //   return;
      // }
      // 리뷰 탭 로그인 시에만 필요하므로 값 삭제해주는 로직
      textRef.current.focus();
      sessionStorage.removeItem('preLoginState');
    } catch {
      sessionStorage.removeItem('preLoginState');
    }
  }, [location.pathname, location.search]);

  const makeQuery = (query) => {
    return isModify
      ? {
          ...query,
          comment: state.comment,
          deleteImages: state.deleteImages,
          images: state.images,
          parentCode,
          ratingNumber: state.score,
          secretStatusCode: isSecret ? '1' : '0',
        }
      : {
          ...query,
          comment: state.comment,
          images: state.images,
          parentCode,
          ratingNumber: state.score,
          secretStatusCode: isSecret ? '1' : '0',
        };
  };

  const _onSubmit = async (query) => {
    if (writing) return;

    const _query = makeQuery(query);
    if (!_query.comment.trim()) {
      showToast('댓글 내용을 입력해 주십시오.');
      return;
    }
    const param = isModify ? id : parentCode;
    if (type === 1 && member && member.memberType === 2)
      _query.ratingNumber = 0;
    setWriting(true);
    const _res = await onSubmit(_query, param);
    setWriting(false);
    if (_res.code === 'failure') {
      showToast('댓글 작성에 실패했습니다.');
    } else {
      showToast(toastMessage);
      dispatch({ type: Action.CLEAR });
      if (textRef.current) {
        textRef.current.value = '';
      }
      if (!isReply && isSecret) {
        setIsSecret(false);
      } else {
        _query.ratingNumber = null;
        if (secretGroup) {
          _query.secretStatusCode = '1';
        }
      }
      if (type === 2) {
        _query.ratingNumber = null;
      }
    }
  };

  const handleNotLoggedIn = () => {
    const scrollPos = { x: window.scrollX, y: window.scrollY };

    action.open(View.CHANNEL, {
      onOk: () => {
        try {
          const redirectUrl = new URL(window.location.href);
          let pathname = decodeURI(window.location.pathname);
          if (tabState) {
            pathname = `${pathname}/${tabState}`;
          }

          redirectUrl.pathname = pathname;

          sessionStorage.setItem(
            'preLoginState',
            JSON.stringify({ path: pathname, scroll: scrollPos })
          );

          window.location.href = getAccountUrl({
            redirectUrl: redirectUrl.href,
          });
        } catch {
          window.location.href = getAccountUrl({
            redirectUrl: window.location.href,
          });
        }
      },
    });
  };

  const _handleImageSelect = (e) => {
    const { files } = e.target;
    for (const file of files) {
      if (!file.type.includes('image/')) {
        showToast('업로드가 허용된 파일이 아닙니다.');
        return;
      }
      if (
        file.type.includes('heif') ||
        file.type.includes('heic') ||
        file.type.includes('tiff') ||
        file.type.includes('avif')
      ) {
        showToast('업로드가 허용된 파일 형식이 아닙니다.');
        return;
      }

      const reader = new FileReader();
      reader.onload = (pe) => {
        dispatch({
          fileName: file.name,
          payload: pe.target.result,
          type: Action.ADD_IMAGE,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const _handleImageRemove = (image) =>
    dispatch({ payload: image, type: Action.REMOVE_IMAGE });

  const _handleScore = (score) => {
    if (memberIdentity) {
      dispatch({ payload: score, type: Action.SCORES });
      return;
    }

    handleNotLoggedIn();
  };

  const parseUrl = (url) => {
    if (!url) {
      return '';
    }
    if (url.indexOf('http') >= 0) {
      return url;
    }
    return `https://${url}`;
  };

  const handleCheckboxChange = () => {
    setIsSecret((prev) => !prev);
  };

  return (
    <div ref={scrollRef || null} className="item fixed" id="review-panel">
      <div className="info">
        <div className="img-block profile">
          <Image scale="1x1_profile" src={parseUrl(memberProfile)} />
        </div>
        <div className="info-area">
          <div className="name">{memberName || '이름없음'}</div>
          {!isReply && type !== 2 && member && (
            <ScoreViewNew
              score={state.score}
              onScores={_handleScore}

              // ? () => {
              //     sessionStorage.setItem(
              //       'preLoginState',
              //       JSON.stringify({ isReviews: `/${tabState}` }),
              //     );
              //     action.open(View.CHANNEL);
              //   }
              // : _handleScore
            />
          )}
        </div>
      </div>
      <div
        className="comment"
        id={page === 'content' && !memberIdentity ? 'content-link-parent' : ''}
        onClick={() => (!memberIdentity ? handleNotLoggedIn() : null)}
      >
        <textarea
          ref={textRef}
          className="textarea-field wpc100"
          id={isContentText ? 'content-link-text' : ''}
          name="comment"
          placeholder={
            memberIdentity
              ? isReply
                ? '답글을 입력하세요.'
                : placeHolder
              : '로그인 하셔야 이용하실 수 있습니다.'
          }
          readOnly={!memberIdentity}
          rows="8"
          onChange={onChangeText}
          onFocus={() => {
            if (isContentText) onChangeText();
          }}
        />
      </div>
      <div className="btn-area">
        <div className="secret">
          {!isReply && (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={(e) => {
                if (!memberIdentity) {
                  e.preventDefault();
                  handleNotLoggedIn();
                }
              }}
            >
              <input
                checked={isSecret}
                id={isContentText ? 'content-link-secret' : ''}
                type="checkbox"
                onChange={handleCheckboxChange}
              />
              <label
                style={{
                  marginLeft: '4px',
                }}
                onClick={handleCheckboxChange}
              >
                비밀 댓글
              </label>
            </div>
          )}
        </div>
        <ul>
          {children}
          <li>
            <Button.File
              accept="image/gif, image/jpeg, image/png, image/jpg, image/webp"
              className="btn blue"
              disabled={!memberIdentity}
              handleNotLoggedIn={handleNotLoggedIn}
              onChange={_handleImageSelect}
            >
              사진첨부
            </Button.File>
          </li>
          <li>
            <button
              className="btn blue use-write-comment"
              type="button"
              onClick={() =>
                !memberIdentity ? handleNotLoggedIn() : _onSubmit(query)
              }
            >
              {isModify ? '댓글 수정' : '댓글 달기'}
            </button>
          </li>
        </ul>
      </div>
      <ImageForm
        fileNames={state.fileNames || []}
        images={state.images}
        onRemove={_handleImageRemove}
      />
    </div>
  );
};

export default memo(TextForm);
