import { useEffect } from 'react';

const useBackForwardCache = () => {
  useEffect(() => {
    // 애플리케이션이 새롭게 초기화되는지 확인 하는 리스너
    // 매 document 로드 시 마다 호출됩니다.
    // SPA 경우 맨 처음만 호출됩니다.
    const onPageShow = (event) => {
      // restored 상태인 경우 새로고침 합니다.
      // 예를들어 외부 링크 등으로 이동 됬다가 다시 돌아왔을 때 restore 되는 프로세스 등입니다.
      if (event.persisted) {
        window.location.reload();
      }
    };
    window.addEventListener('pageshow', onPageShow);
    return () => {
      window.removeEventListener('pageshow', onPageShow);
    };
  }, []);
  return null;
};

export default useBackForwardCache;
